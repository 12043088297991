<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
@font-face {
      font-family: 'Montserrat';
      src: local('Montserrat'), url('./assets/fonts/Montserrat-Regular.ttf')  format('truetype'), 
    }
</style>