import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins/validations'
import { ValidationObserver, ValidationProvider} from 'vee-validate';
import i18n from './i18n'
import '@/assets/style.css'
import VueGtag from 'vue-gtag'
import VueCookies from 'vue-cookies'
import FlagIcon from 'vue-flag-icon'
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
Vue.use(VuePlyr)
import VueCalendly from 'vue-calendly';
import VueSocial from "@growthbunker/vuesocial";

import MyIcon from '@/components/ui/MyIcon.vue'
import getSocialNetwork from './plugins/getSocialNetwork'
import axios from "axios";

Vue.config.productionTip = false

Vue.component('my-icon', MyIcon)

Vue.prototype.$getSocialNetwork = getSocialNetwork


Vue.component('ValidationProvider', ValidationProvider);

Vue.component('ValidationObserver', ValidationObserver);

Vue.use(VueCookies, { expires: '7d'})

Vue.use(VueCalendly);
Vue.use(FlagIcon)
Vue.use(VueSocial, {
  // Specify the path of the folder where the logos are stored.
  iconPath: '/social-networks',
})

axios.interceptors.request.use(
  function(config) {
        config.withCredentials = true;
        config.headers['Content-Type'] = "application/json";
        config.headers['Accept'] = "application/json";
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);


axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    router.push('/')
  }
  return Promise.reject(error)
})

Vue.use(VueGtag, {
  config: {
      id: 'G-00YHF67M8C',
      params: {
          send_page_view: true
      }
  }
})

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
