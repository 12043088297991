<template>
    <v-container fluid>
        <div class="d-none d-lg-block ma-16 pa-16" v-html="$t('privacy')">

            

        </div>
        <div class="d-lg-none" v-html="$t('privacy')"></div>
    </v-container>
</template>
<script>
export default{
    name: "Privacy",
    mounted(){
        this.$i18n.locale = this.$route.params.lang
        
    }
}
</script>