<template>
    <v-container fluid class="grad fill-height ma-0 pa-0">
        <!-- DESKTOP VERSION d-none d-lg-block -->
        <div >
            <v-row >
                <v-col cols="12">
                    <v-card class="transparent elevation-0">
                        <v-card-title>                                
                            <v-img
                                style="margin: auto;"
                                contain
                                max-width="500px"
                                src="@/assets/icons/title_negativo.png"
                                lazy-src="@/assets/icons/title_negativo.png"
                            />
                        </v-card-title>
                        <v-card-title class="text-h2 mt-6 white--text font-weight-bold justify-center">
                            {{ $t('TRIAL.SUCCESS') }}!
                        </v-card-title>
                        <v-card-title class="text-h5 mt-6 text-left white--text font-weight-bold justify-center text-center" style="white-space:pre-line;">
                            {{ $t('TRIAL.SUCCESS_DESCRIPTION') }}.
                        </v-card-title>
                        <v-card-actions>                                
                            <v-container class="d-flex justify-center white--text" style="white-space: pre-line;" fluid fill-height>
                            <table>
                                <tr>
                                    <td colspan="2" class="text-center">
                                        <div class="text-h6 font-weight-medium">
                                            {{ $t('PRE_FOOTER.contacts') }}                        
                                        </div>
                                    </td>
                                </tr>
                                <tr class="text-h6 font-weight-light">
                                    <td><v-icon color="white">mdi-email</v-icon></td>
                                    <td>info@reportlake.com</td>
                                </tr>
                                <tr class="text-h6 font-weight-light">
                                    <td><v-icon color="white">mdi-clock</v-icon></td>
                                    <td>{{ $t('PRE_FOOTER.work_week') }} - 9h {{ $t('PRE_FOOTER.to') }} 18h</td>
                                </tr>
                            </table>
                        </v-container>
                        </v-card-actions>
                    </v-card>
                </v-col>
                
            </v-row>
        </div>
    </v-container>
</template>
<script>
export default{
    name: 'SuccessTrial',
    mounted(){
        
    }
}
</script>
<style>
.grad{
    background-image: linear-gradient(#0B0E17, #3D4A6D);
}

.semi-circle-success {    
    --shadow-size: 4vmin;
  aspect-ratio: 1;
  background: #3D4A6D;
  border-radius: 50%;
  box-shadow:
    var(--shadow-size) var(--shadow-size) 0 var(--shadow-size) #364363,
    calc(2 * var(--shadow-size)) calc(2 * var(--shadow-size)) 0 calc(2 * var(--shadow-size)) #26324C
  ;
  width: 90vmin;
  position: absolute;
  bottom: 100%;
  right: 100%;
  transform: translate(75%,75%); 
  z-index: 1;
}
</style>