<template>
    <v-container fluid>
        <v-card
            class="elevation-0"
            color="transparent"
          >
          
            <div class="d-flex flexjustify-space-between">                
              <v-avatar
                class="ma-3"
                size="150px"
                tile
              >
                <v-img :src="Photo" contain></v-img>
              </v-avatar>

              <div>
                <v-card-title
                  class="text-h5 text-left secondary--text font-weight-bold"
                  style="word-break: keep-all;"
                >
                    <a href="" @click="$router.push('/' + $i18n.locale + '/article/' + ID)">{{ Title }}</a>
                </v-card-title>
                <v-card-subtitle>
                    {{ DataArticle }}
                </v-card-subtitle>

                <v-card-actions>
                    <v-avatar>
                        <img
                            :src="AutorPhoto"
                            alt="John"
                        >
                    </v-avatar>
                    <v-row>
                      <v-col cols="12">
                        <span class="font-weight-bold ml-2 primary--text">{{Author}}</span>
                        <br/>
                        <gb-social-button 
                              v-if="Linkedin != null"
                              @click="openSocialNetwork('linkedin', Linkedin)" 
                              network="linkedin" size="mini" 
                              :reverse="true" theme="light" 
                              style="border: none;" 
                              class="gb-social-button__focuser">
                          </gb-social-button>
                      </v-col>
                    </v-row>
                    
                </v-card-actions>
              </div>
            </div>
          </v-card>
    </v-container>
</template>
<style>
.gb-social-button__focuser{
  border: none !important;
}
</style>
<script>
export default{
    props:{
        ID: String,
        DataArticle: String,
        Title: String,
        Author: String,
        Photo: String,
        AutorPhoto: String,
        Linkedin: String
    },
    methods:{
      openSocialNetwork(social, link){
        if(social == 'linkedin')
          window.open(link, '_blank')
      }
    }
}
</script>