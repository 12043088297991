import BaseApi from "./BaseApi";

export default class Trial extends BaseApi{
    construct() {}
    

   
    static async subscribe(params) {
        return await Trial.getAxiosInstance().post(Trial.getApiUrl(),params);
    }
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/trial/";
    }
}