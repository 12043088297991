<template>
    <v-container fluid class="fill-height">
        <!-- DESKTOP VERSION d-none d-lg-block -->
        <div class="d-none d-lg-block">
            <v-row>
                <v-col cols="7" class="semi-circle">
                    <v-row class="ma-8 px-12">
                        <v-col cols="12">
                            <v-card-title class="text-h2 white--text font-weight-bold">{{ $t('TRIAL.TITLE') }}</v-card-title>
                            <v-card-title class="text-h4 text-left white--text font-weight-bold">
                                {{ $t('TRIAL.SUBTITLE') }}
                            </v-card-title>
                        </v-col>
                        <v-col cols="12">
                            <v-col cols="10">
                                <validation-observer ref="form" v-slot="{ invalid }">
                                    <v-form @submit.prevent="subscribe" :class="{'shake' : animated}">
                                        <fieldset :disabled="loading">
                                            <v-card>
                                                <v-card-title class="primary">
                                                    <span v-if="false" class="white--text text-h5 font-weight-medium"><v-icon color="white" class="mr-2">mdi-comment</v-icon>{{ $t('CONTACTS.NEW_MESSAGE') }}</span>
                                                </v-card-title>
                                                <v-card-text>
                                                        <v-row class="mt-4">
                                                            <v-col cols="12" md="12">
                                                                <validation-provider v-slot="{ errors }" vid="nome" :name="$t('TRIAL.COMPANY_NAME')" rules="required">
                                                                    <v-text-field
                                                                        class="font-weight-bold"
                                                                        v-model="fields.company_name"
                                                                        :label="$t('TRIAL.COMPANY_NAME')"
                                                                        :error-messages="errors"
                                                                    ></v-text-field>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="12" md="12">
                                                                <validation-provider v-slot="{ errors }" vid="nome" :name="$t('TRIAL.NIF')" rules="required">
                                                                    <v-text-field
                                                                        class="font-weight-bold"
                                                                        v-model="fields.nif"
                                                                        :label="$t('TRIAL.NIF')"
                                                                        :error-messages="errors"
                                                                    ></v-text-field>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="12" md="12">
                                                                <validation-provider v-slot="{ errors }" vid="nome" :name="$t('TRIAL.PROJECT_NAME')" rules="required|remove_spec_char|no_accent|small_letter">
                                                                    <v-text-field
                                                                        class="font-weight-bold"
                                                                        v-model="fields.project_name"
                                                                        pattern="[0-9a-zA-Z_.-]*"
                                                                        :error-messages="errors"
                                                                        :hint="$t('TRIAL.PROJECT_HINT')"
                                                                        persistent-hint
                                                                        :label="$t('TRIAL.PROJECT_NAME')"
                                                                    ></v-text-field>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="12" md="6">
                                                                <validation-provider v-slot="{ errors }" vid="Email" name="Email" rules="required|email">
                                                                    <v-text-field
                                                                        class="font-weight-bold"
                                                                        v-model="fields.email"
                                                                        label="Email"
                                                                        :error-messages="errors"
                                                                    ></v-text-field>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="12" md="6">
                                                                <validation-provider v-slot="{ errors }" vid="Numerp" :name="$t('TRIAL.TELEPHONE')" rules="required">
                                                                    <v-text-field
                                                                        class="font-weight-bold"
                                                                        v-model="fields.telephone"
                                                                        :label="$t('TRIAL.TELEPHONE')"
                                                                        :error-messages="errors"
                                                                    ></v-text-field>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="12" md="6">
                                                                <validation-provider v-slot="{ errors }" vid="country_vid" :name="$t('TRIAL.COUNTRY')" rules="required">
                                                                    <v-autocomplete
                                                                        class="font-weight-bold"
                                                                        :items="countries"
                                                                        item-text="nome"
                                                                        item-id="nome"
                                                                        v-model="fields.country"
                                                                        :error-messages="errors"
                                                                        :label="$t('TRIAL.COUNTRY')"
                                                                    ></v-autocomplete>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="12" md="6">
                                                                <validation-provider v-slot="{ errors }" vid="company_size" :name="$t('TRIAL.SIZE')" rules="required">
                                                                    <v-autocomplete
                                                                        class="font-weight-bold"
                                                                        :items="size_company[$i18n.locale]"
                                                                        v-model="fields.size"
                                                                        :label="$t('TRIAL.SIZE')"
                                                                        :error-messages="errors"
                                                                    ></v-autocomplete>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="12" md="6">
                                                                <v-autocomplete
                                                                    class="font-weight-bold"
                                                                    :items="languages"
                                                                    item-value="id"
                                                                    item-text="name"
                                                                    v-model="fields.language"
                                                                    :label="$t('TRIAL.LANGUAGE')"
                                                                ></v-autocomplete>
                                                            </v-col>
                                                            <v-col cols="12" md="6">
                                                                <v-autocomplete
                                                                    class="font-weight-bold"
                                                                    :items="coins"
                                                                    v-model="fields.coin"
                                                                    :label="$t('TRIAL.COIN')"
                                                                ></v-autocomplete>
                                                            </v-col>
                                                            <v-col cols="12" md="6">
                                                                <validation-provider v-slot="{ errors }" vid="company_size" :name="$t('TRIAL.SIZE')" rules="required">
                                                                    <v-checkbox
                                                                        v-model="fields.terms_acceptance"
                                                                        :error-messages="errors"
                                                                    >
                                                                    <div slot='label'>{{$t('accept_termos')}} <a href="#" @click="openTerms">{{$t('termos_condicoes')}}</a></div>
                                                                </v-checkbox>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="12" class="mt-n8">
                                                                <v-checkbox
                                                                    v-model="fields.marketing"
                                                                    :label="$t('TRIAL.MARKETING')"
                                                                ></v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                </v-card-text>
                                                <v-card-actions class="mt-n6">
                                                    <v-row align="center" justify="space-around">
                                                        <v-col align="center" justify="space-around">
                                                            <v-btn :disabled="invalid || fields.terms_acceptance == false" depressed color="warning" class="mr-5 ml-5 font-weight-bold mb-2" type="submit">
                                                                {{ $t('TRIAL.SEND') }}
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-actions>
                                            </v-card>
                                        </fieldset>
                                    </v-form>
                                </validation-observer>
                            </v-col>
                        </v-col>                        
                    </v-row>
                </v-col>
                <v-col cols="5">
                    <v-row class="ma-8 px-12">   
                        <v-col cols="12">
                            <div class="circleContacts white--text" style="margin: auto;">
                                <div class="circleContacts-txt">Envie-nos um email<br/> que responderemos<br/> o mais brevemente<br/> possível</div>
                                <div class="circleContacts-img">@</div>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div style="margin-right: 50%">
                                <div class="second-circle" style="margin: auto;">
                                    <div class="second-circle-txt white--text">Agende uma<br/>chamada connosco<br/>para tirar todas as<br/>suas dúvidas!</div>
                                    <div class="second-circle-img"><i class="mdi mdi-phone"></i></div>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" class="mt-n4">
                            <div style="margin-right: 75%;">
                                <div class="small-circle" style="margin: auto;">
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" class="mt-n4">
                            <div style="margin-right: 60%;">
                                <div class="xsmall-circle" style="margin: auto;">
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" v-if="false">
                            <div style="margin-left: 30%; margin-top: -10%">
                                <v-btn x-large class="warning" style="width: 300px;" @click="emailSender()">{{ $t('CONTACTS.SEND_EMAIL') }}</v-btn> 
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div style="margin-left: 30%;">
                                <v-btn x-large class="warning" style="width: 300px;" @click="scheduleCall()">{{ $t('CONTACTS.SCHEDULE_CALL') }}</v-btn>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div style="margin-left: 30%;">
                                <v-btn x-large class="warning" style="width: 300px;" @click="clickHandler()">{{ $t('CONTACTS.SEND_MESSAGE') }}</v-btn>
                            </div>
                        </v-col>

                    </v-row>
                    
                </v-col>
            
            
            </v-row>
        </div>
        <!-- MOBILE VERSION -->
        <div class="d-md-none">
            <v-row class="black-blue" >
                <div class="semi-circle-mobile"></div>
                <v-col cols="12" style="z-index: 2;">
                    <v-card-title class="text-h5 white--text font-weight-black">{{ $t('CONTACTS.SPEAK') }}</v-card-title>
                </v-col>
                <v-col cols="12" class="mt-n12" style="z-index: 2;">
                    <v-card-title class="text-h6 text-left white--text font-weight-medium">{{ $t('CONTACTS.SCHEDULE') }}</v-card-title>
                </v-col>
                <v-col cols="12" style="z-index: 2;">
                    <validation-observer ref="formSender" v-slot="{ invalid }">
                        <v-form @submit.prevent="submit" :class="{'shake' : animated}">
                            <fieldset :disabled="loading">
                                <v-card>
                                    <v-card-title class="yellow-back">
                                        <span class="white--text text-subtitle-1 font-weight-bold"><v-icon small color="white" class="mr-2">mdi-comment</v-icon>{{ $t('CONTACTS.NEW_MESSAGE') }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row class="mt-4">
                                            <v-col cols="12" md="12">
                                                <validation-provider v-slot="{ errors }" vid="nome" :name="$t('TRIAL.COMPANY_NAME')" rules="required">
                                                    <v-text-field
                                                        class="font-weight-bold"
                                                        v-model="fields.company_name"
                                                        :label="$t('TRIAL.COMPANY_NAME')"
                                                        :error-messages="errors"
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="12" md="12">
                                                <validation-provider v-slot="{ errors }" vid="nome" :name="$t('TRIAL.NIF')" rules="required">
                                                    <v-text-field
                                                        class="font-weight-bold"
                                                        v-model="fields.nif"
                                                        :label="$t('TRIAL.NIF')"
                                                        :error-messages="errors"
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="12" md="12">
                                                <validation-provider v-slot="{ errors }" vid="nome" :name="$t('TRIAL.PROJECT_NAME')" rules="required|remove_spec_char|small_letter">
                                                    <v-text-field
                                                        class="font-weight-bold"
                                                        v-model="fields.project_name"
                                                        :error-messages="errors"
                                                        :hint="$t('TRIAL.PROJECT_HINT')"
                                                        persistent-hint
                                                        :label="$t('TRIAL.PROJECT_NAME')"
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <validation-provider v-slot="{ errors }" vid="Email" name="Email" rules="required|email">
                                                    <v-text-field
                                                        class="font-weight-bold"
                                                        v-model="fields.email"
                                                        label="Email"
                                                        :error-messages="errors"
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <validation-provider v-slot="{ errors }" vid="Numerp" :name="$t('TRIAL.TELEPHONE')" rules="required">
                                                    <v-text-field
                                                        class="font-weight-bold"
                                                        v-model="fields.telephone"
                                                        :label="$t('TRIAL.TELEPHONE')"
                                                        :error-messages="errors"
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <validation-provider v-slot="{ errors }" vid="country_vid" :name="$t('TRIAL.COUNTRY')" rules="required">
                                                    <v-autocomplete
                                                        class="font-weight-bold"
                                                        :items="countries"
                                                        item-text="nome"
                                                        item-id="nome"
                                                        v-model="fields.country"
                                                        :error-messages="errors"
                                                        :label="$t('TRIAL.COUNTRY')"
                                                    ></v-autocomplete>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <validation-provider v-slot="{ errors }" vid="company_size" :name="$t('TRIAL.SIZE')" rules="required">
                                                    <v-autocomplete
                                                        class="font-weight-bold"
                                                        :items="size_company[$i18n.locale]"
                                                        v-model="fields.size"
                                                        :label="$t('TRIAL.SIZE')"
                                                        :error-messages="errors"
                                                    ></v-autocomplete>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-autocomplete
                                                    class="font-weight-bold"
                                                    :items="languages"
                                                    item-value="id"
                                                    item-text="name"
                                                    v-model="fields.language"
                                                    :label="$t('TRIAL.LANGUAGE')"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-autocomplete
                                                    class="font-weight-bold"
                                                    :items="coins"
                                                    v-model="fields.coin"
                                                    :label="$t('TRIAL.COIN')"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12">
                                                <validation-provider v-slot="{ errors }" vid="company_size" :name="$t('TRIAL.SIZE')" rules="required">
                                                    <v-checkbox
                                                        v-model="fields.terms_acceptance"
                                                        :error-messages="errors"
                                                    >
                                                    <div slot='label'>{{$t('accept_termos')}} <a href="#" @click="openTerms">{{$t('termos_condicoes')}}</a></div>
                                                </v-checkbox>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="12" class="mt-n8">
                                                <v-checkbox
                                                    v-model="fields.marketing"
                                                    :label="$t('TRIAL.MARKETING')"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                <v-card-actions>
                                    <v-row align="center" justify="space-around">
                                        <v-col align="center" justify="space-around">
                                            <v-btn :disabled="invalid || fields.terms_acceptance == false" depressed color="warning" class="mr-5 ml-5 font-weight-bold mb-2" type="submit">
                                                {{ $t('TRIAL.SEND') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                                </v-card>
                            </fieldset>
                        </v-form>
                    </validation-observer>
                </v-col>       
                <v-col cols="12" style="z-index: 2;">
                    <div class="second-circle" style="margin: auto;">
                        <div class="second-circle-txt white--text">Agende uma<br/>chamada connosco<br/>para tirar todas as<br/>suas dúvidas!</div>
                        <div class="second-circle-img"><i class="mdi mdi-phone"></i></div>
                    </div>
                </v-col>
                <v-col cols="12" style="z-index: 2;">
                    <div class="circleContacts" style="margin: auto;">
                        <div class="circleContacts-txt white--text">Envie-nos um email<br/> que responderemos<br/> o mais brevemente<br/> possível</div>
                        <div class="circleContacts-img">@</div>
                    </div>
                </v-col>
                <v-col cols="12" class="mt-n4" style="z-index: 2;">
                    <div style="margin-left: 25%;">
                        <div class="small-circle" style="margin: auto;">
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" class="mt-n4" style="z-index: 2;">
                    <div style="margin-left: 15%;">
                        <div class="xsmall-circle" style="margin: auto;">
                        </div>
                    </div>
                </v-col>         
                <v-col cols="12">                
                    <div class="text-center">
                        <v-col cols="12" v-if="false">
                            <v-btn x-large class="font-weight-bold white--text" color="#E7C02C" style="width: 300px;" @click="emailSender()">{{ $t('CONTACTS.SEND_EMAIL') }}</v-btn>                               
                        </v-col>
                        <v-col cols="12">
                            <v-btn x-large class="font-weight-bold white--text" color="#E7C02C" style="width: 300px;" @click="scheduleCall()">{{ $t('CONTACTS.SCHEDULE_CALL') }}</v-btn>
                        </v-col>
                        <v-col cols="12">
                            <v-btn x-large class="font-weight-bold white--text" color="#E7C02C" style="width: 300px;" @click="clickHandler()">{{ $t('CONTACTS.SEND_MESSAGE') }}</v-btn>
                        </v-col>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="text-center">
                        <v-img max-width="50px" style="margin: auto; display: block;" src="@/assets/icon_dream.png"></v-img>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="text-center">
                        <table style="margin: 0 auto;">
                            <tr>
                                <td colspan="2">
                                    <div class="text-h6 white--text font-weight-medium">
                                        {{ $t('PRE_FOOTER.contacts') }}                        
                                    </div>
                                </td>
                            </tr>
                            <tr class="text-body font-weight-light white--text">
                                <td><v-icon color="white">mdi-email</v-icon></td>
                                <td>info@reportlake.com</td>
                            </tr>
                            <tr class="text-body font-weight-light white--text">
                                <td><v-icon color="white">mdi-clock</v-icon></td>
                                <td>{{ $t('PRE_FOOTER.work_week') }} - 9h {{ $t('PRE_FOOTER.to') }} 18h</td>
                            </tr>
                            <tr class="text-body font-weight-light white--text">
                                <td><v-icon color="white">mdi-phone</v-icon></td>
                                <td>+351 220 280 430</td>
                            </tr>                            
                        </table>
                    </div>
                </v-col>
                <v-col cols="12" class="my-6">
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>


<script>
import countries_pt from '@/assets/arrays/countries/pt.json'

import Trial from '@/api/Trial.js'

import * as FormData from 'form-data'; 

import Mailgun from 'mailgun.js';  

export default{
    data(){
        return{
            loading: true,
            animated: false,
            countries: [],
            languages: [
                {
                    id: 'pt',
                    name: 'Português'
                },
                {
                    id: 'en',
                    name: 'English'
                }
            ],
            coins:['€', 'R$'],
            fields:{
                company_name: null,
                terms_acceptance: false,
                project_name: null,
                telephone: null,
                country: 'Portugal',
                size: null,
                email: null,
                coin: '€',
                language: 'pt',
                nif: null,
                marketing: true
            },
            size_company:{
                pt:[
                    '< 5 empregados',
                    '5 - 20 empregados',
                    '20 - 50 empregados',
                    '50 - 250 empregados',
                    '> 250 empregados'
                ],                
                en:[
                    '< 5 employees',
                    '5 - 20 employees',
                    '20 - 50 employees',
                    '50 - 250 employees',
                    '> 250 employees'
                ]
            }
        }
    },
    mounted(){
        this.loading = false
        window.scrollTo(0, 0)
        this.countries = countries_pt
    },
    methods:{
        openTerms(){
            window.open('/' + this.$i18n.locale + '/terms-and-conditions', '_blank')
        },
        subscribe(){

            /*let cookie = this.$cookies.get('reportlake_website_cookie');
            let enter = true
            if(cookie == undefined)
                enter = false
            if(cookie == null)
                enter = false
            if(cookie.statistics == false)
                enter = false

            if(enter){
                this.$gtag.event('send_email', {
                    'event_category': 'EnviarEmail',
                    'event_label': 'EnviarEmail',
                    'value': 'send_email'
                })
            }*/

            Trial.subscribe(this.fields)
            .then(() => {


            }) // logs response data 

            .catch(err => console.error(err)); // logs any error 

           
            this.alertSystem()

            //this.success = true
            this.$router.push('/' + this.$i18n.locale + '/success/trial')
        },
        alertSystem(){
            const mailgun = new Mailgun(FormData); 

            const mg = mailgun.client( 

                    { 

                        username: 'api',  

                        key: '7cd99b1d8469e35613685630190ce213-10eedde5-8c351df0', 

                        url:"https://api.eu.mailgun.net" 

                    } 

                ); 

            /*let message =   "Nova mensagem!<br/><br/>" +
                            "De: " + this.fields.email + "<br/><br/>" +
                            "Título: " + this.fields.title + "<br/><br/>" +
                            "Descrição: " + this.fields.description*/

            let message = "Nova mensagem!<br/><br/>" + 
                        "Foi criada uma nova conta no Reportlake. Contra Trial: <br/><br/>" +
                        "Empresa: " + this.fields.company_name + "<br/><br/>" +
                        "Dominio: " + this.fields.project_name + "<br/><br/>" +
                        "Contacto: " + this.fields.telephone + "<br/><br/>" +
                        "Pais: " + this.fields.country + "<br/><br/>" +
                        "Size: " + this.fields.size + "<br/><br/>" +
                        "Email: " + this.fields.email + "<br/><br/>" +
                        "Coin: " + this.fields.coin + "<br/><br/>" +
                        "Language: " + this.fields.language + "<br/><br/>" +
                        "NIF: " + this.fields.nif + "<br/><br/>";


            mg.messages.create('email.reportlake.com', { 

            from: "info@reportlake.com", 

            to: ["alvaro@dreamdealtech.pt", "sales@reportlake.pt"], 

            subject: "Nova Contra TRIAL - Reportlake", 

            text: "Nova mensagem", 

            html: message

            }) 

            .then(msg => console.log(msg)) // logs response data 

            .catch(err => console.error(err)); // logs any error 
        },
        clickHandlerMobile(){
            const self = this
            self.animated = true
            setTimeout(() => {
                self.animated = false
            }, 1000)
            this.$refs.formSender.$el.scrollIntoView({ behavior: 'smooth' })
        },
        emailSender(){
            window.location.href = "mailto:info@reportlake.com?subject=&body=";
        },
        scheduleCall(){
            window.open('schedule-call', '_blank')
        },
        clickHandler(){
            const self = this
            self.animated = true
            setTimeout(() => {
                self.animated = false
            }, 1000)
        }
    }
}
</script>
<style>
* {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
}

.black-blue{
    background-color: #111B2D;
}

.yellow-back{
    background-color: #E7C02C;
}

.semi-circle {    
    aspect-ratio: 1/1; /* 1:1 aspect-ratio, so the height we specified in the next line and width,                               becomes the same */
    background: #505c7b;
    border-radius: 0 80% 80% 0;
    box-shadow: inset -3.5rem 1px 0 #7d869c, 
                    3.5rem 1px 0 #bec2cd;
}

.semi-circle-mobile {
  --shadow-size: 4vmin;
  aspect-ratio: 1;
  background: #3D4A6D;
  border-radius: 50%;
  box-shadow:
    var(--shadow-size) var(--shadow-size) 0 var(--shadow-size) #364363,
    calc(2 * var(--shadow-size)) calc(2 * var(--shadow-size)) 0 calc(2 * var(--shadow-size)) #26324C
  ;
  width: 90vmin;
  position: absolute;
  bottom: 100%;
  right: 100%;
  transform: translate(75%,75%); 
  z-index: 1;
}



.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(12px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-16px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(16px, 0, 0);
  }
}

.circleContacts{
        width: 250px;
        height: 250px;
        background: #9DA6BA;
        border-radius: 50%;

        /* TO POSITION TEXT BLOCK PROPERLY */
        line-height: 50px;
        position: relative;
    }

    /* MATCHING HEIGHT */
    .circleContacts::after{
        content: '';
        display: block;
        padding-bottom: 100%;
    }

    /* CENTER TEXT IN CIRCLE */
    .circleContacts-txt{
        position: absolute;
        line-height: 20px;
        bottom: 50%;
        font-weight: light;
        width: 100%;
        text-align: center;
        font-size: 15px;
    }


    .circleContacts-img{
        position: absolute;
        line-height: 0;
        bottom: 25%;
        width: 100%;
        font-weight: 900;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        color: #111B2D;
        font-size: 100px;
        text-align: center;
    }

    .second-circle{
        width: 200px;
        height: 200px;
        background: #9DA6BA;
        border-radius: 50%;

        /* TO POSITION TEXT BLOCK PROPERLY */
        line-height: 50px;
        position: relative;
    }

    /* MATCHING HEIGHT */
    .second-circle::after{
        content: '';
        display: block;
        padding-bottom: 100%;
    }

    /* CENTER TEXT IN CIRCLE */
    .second-circle-txt{
        position: absolute;
        line-height: 20px;
        bottom: 50%;
        font-weight: light;
        width: 100%;
        text-align: center;
        font-size: 15px;
    }

    .second-circle-img{
        position: absolute;
        line-height: 0;
        bottom: 25%;
        width: 100%;
        font-weight: 900;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        color: #111B2D;
        font-size: 100px;
        text-align: center;
    }

    .small-circle{
        width: 75px;
        height: 75px;
        background: #9DA6BA;
        border-radius: 50%;
    }

    .xsmall-circle{
        width: 42px;
        height: 42px;
        background: #9DA6BA;
        border-radius: 50%;
    }


@media (min-width: 850px) {
    .circleContacts{
        width: 350px;
        height: 350px;
        background: #111B2D;
        border-radius: 50%;

        /* TO POSITION TEXT BLOCK PROPERLY */
        line-height: 50px;
        position: relative;
    }

    /* MATCHING HEIGHT */
    .circleContacts::after{
        content: '';
        display: block;
        padding-bottom: 100%;
    }

    /* CENTER TEXT IN CIRCLE */
    .circleContacts-txt{
        position: absolute;
        line-height: 20px;
        bottom: 50%;
        font-weight: 400;
        width: 100%;
        text-align: center;
        font-size: 20px;
    }


    .circleContacts-img{
        position: absolute;
        line-height: 0;
        bottom: 25%;
        width: 100%;
        font-weight: 900;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        color: #E7C02C;
        font-size: 100px;
        text-align: center;
    }

    .second-circle{
        width: 275px;
        height: 275px;
        background: #111B2D;
        border-radius: 50%;

        /* TO POSITION TEXT BLOCK PROPERLY */
        line-height: 50px;
        position: relative;
    }

    /* MATCHING HEIGHT */
    .second-circle::after{
        content: '';
        display: block;
        padding-bottom: 100%;
    }

    /* CENTER TEXT IN CIRCLE */
    .second-circle-txt{
        position: absolute;
        line-height: 20px;
        bottom: 50%;
        font-weight: 300;
        width: 100%;
        text-align: center;
        font-size: 20px;
    }

    .second-circle-img{
        position: absolute;
        line-height: 0;
        bottom: 25%;
        width: 100%;
        font-weight: 900;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        color: #E7C02C;
        font-size: 100px;
        text-align: center;
    }

    .small-circle{
        width: 75px;
        height: 75px;
        background: #111B2D;
        border-radius: 50%;
    }

    .xsmall-circle{
        width: 42px;
        height: 42px;
        background: #111B2D;
        border-radius: 50%;
    }
}



</style>