<template>
    <v-container fluid class="secondary ma-0 pa-0">
         <!-- DESKTOP -->
         <v-container fluid class="d-none d-lg-block">
            <v-row justify="center">
                <v-col cols="12" class="text-h2 font-weight-black white--text text-center text-uppercase">
                    {{$t('MENU.faqs')}}
                </v-col>
            </v-row>
            <v-row justify="center">
                    <v-expansion-panels light style="width: 70%;">
                        <v-col cols="12" md="12" class="text-center elevation-0" v-for="(item,i) in getQuestions" :key="i">
                                <v-expansion-panel 
                                    class="mt-4 rounded-lg elevation-0"
                                    
                                >
                                    <v-expansion-panel-header class="font-weight-black text-body-1">
                                        {{item.title}}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card class="elevation-0" v-if="item.content.type == 'text'">
                                            <v-card-text class="text-left text-body-1" v-for="(line, index) in item.content.text" :key="index + 9000" v-html="line">
                                                
                                            </v-card-text>                                    
                                        </v-card>
                                        <v-card class="elevation-0" v-if="item.content.type == 'bullet'">
                                            <v-card-text class="text-left text-body-1">
                                                <ul>
                                                    <li v-for="(line, index) in item.content.text" :key="index" v-html="line">
                                                    
                                                    </li>
                                                </ul>
                                            </v-card-text>                                    
                                        </v-card>
                                    </v-expansion-panel-content>
                                    <div style="background-color: grey; height: .5px; width: 100%;"></div>
                                </v-expansion-panel>
                        </v-col>
                    </v-expansion-panels>
                <v-col cols="12" md="12" justify="center" class="text-center mb-6">
                    <v-btn small @click="moreOrLess" class="warning rounded-xl font-weight-bold">{{ maxQuestions >= $t('questions.items').length ? $t('globals.see_less') : $t('globals.see_more')}}</v-btn>
                </v-col>
            </v-row>
        </v-container>
         <!-- DESKTOP -->
         <!-- MOBILE -->
         <v-container fluid class="d-sm-none">
            <v-row justify="center">
                <v-col cols="12" class="text-h6 text-center text-uppercase">
                    {{$t('MENU.faqs')}}
                </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
                <v-expansion-panels light class="ma-0 pa-0">
                    <v-col style="width: 100%;" cols="12" md="6" class="ma-0 pa-0 text-center elevation-0" v-for="(item,i) in getQuestions" :key="i">
                            <v-expansion-panel 
                                class="mt-4 rounded-lg elevation-0"
                            >
                                <v-expansion-panel-header class="font-weight-medium text-body-1">
                                    {{item.title}}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card class="elevation-0" v-if="item.content.type == 'text'">
                                        <v-card-text class="text-left text-body-1" v-for="(line, index) in item.content.text" :key="index + 9000" v-html="line">
                                          
                                        </v-card-text>                                    
                                    </v-card>
                                    <v-card class="elevation-0" v-if="item.content.type == 'bullet'">
                                        <v-card-text class="text-left text-body-1">
                                            <ul>
                                                <li v-for="(line, index) in item.content.text" :key="index" v-html="line">
                                                    
                                                </li>
                                            </ul>
                                        </v-card-text>                                    
                                    </v-card>
                                </v-expansion-panel-content>
                                <div style="background-color: grey; height: .5px; width: 100%;"></div>
                            </v-expansion-panel>
                    </v-col>
                </v-expansion-panels>
                <v-col cols="12" md="12" justify="center" class="text-center mb-6">
                    <v-btn small @click="moreOrLess" class="warning rounded-xl font-weight-bold">{{ maxQuestions >= $t('questions.items').length ? $t('globals.see_less') : $t('globals.see_more')}}</v-btn>
                </v-col>
            </v-row>
        </v-container>
         <!-- MOBILE -->
    </v-container>
</template>
<script>
export default{
    name: "FAQS",
    data(){
        return{
            maxQuestions: 5
        }
    },
    computed:{
        getQuestions(){
            return this.$t('questions.items').slice(0, this.maxQuestions)
        }
    },
    methods:{
        moreOrLess(){
            if(this.maxQuestions >= this.$t('questions.items').length)
                this.maxQuestions = 5
            else
            this.maxQuestions = this.maxQuestions + 5;
        }
    },
}
</script>