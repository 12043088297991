<template>
    <v-container fluid class="transparent ma-0 pa-0">
        <v-container fluid class="transparent secondary">
            <!-- Mensal Plans -->
            
            <!-- DESKTOP -->
            <v-row class="d-none d-lg-block gradient-plan background_gray" style="position: relative;">
                <v-col cols="12">
                    <div class="text-h2 font-weight-black primary--text">{{$t('MENU.subscription')}}</div>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="auto">
                            <div class="text-h3  warning--text font-weight-black">{{$t('PLAN.monthly')}}</div>
                        </v-col>
                        <v-col cols="auto">
                            <v-switch 
                                class="mb-4 "
                                v-model="timeSwitcher"
                                label=""
                                inset
                            />
                        </v-col>
                        <v-col cols="auto">
                            <div class="text-h3  warning--text font-weight-black">{{$t('PLAN.anual')}}</div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            
            <!-- MOBILE -->
            <v-row class="d-md-none" style="position: relative;">
                <v-col cols="12">
                    <div class="text-h4 mt-n4 font-weight-black primary--text">{{$t('MENU.subscription')}}</div>
                </v-col>
                <v-col cols="12">
                    <div class="text-h5 mt-n6 warning--text font-weight-black">{{$t('PLAN.monthly')}}</div>
                </v-col>
            </v-row>



            <v-card
                class="d-flex justify-center mt-4"
                color="transparent"
                flat
                tile
                v-if="!timeSwitcher"
            >
                <v-row>
                    <v-col cols="12" md="4">
                        <v-card
                            color="transparent"
                            outlined
                            tile
                        >
                            <PlanCard title="Golden" price="250€" :diskInformation="$t('PLAN.disk4')" :targetTo="$t('PLAN.to10')"/>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card
                            color="transparent"
                            outlined
                            tile
                        >
                            <PlanCard :focus="true" figure="diamond" :diskInformation="$t('PLAN.disk10')" title="Diamond" price="350€" :targetTo="$t('PLAN.to50')"/>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card
                            color="transparent"
                            outlined
                            tile
                        >
                            <PlanCard title="Tailored" figure="hexagon" :price="$t('PLAN.tailored_price')" :targetTo="$t('PLAN.tailored')"/>
                        </v-card>  
                    </v-col>
                </v-row> 
            </v-card>
            <v-card
                class="d-flex justify-center mb-6"
                color="transparent"
                flat
                tile
                v-else
            >
                <v-row>
                    <v-col cols="12" md="4">
                        <v-card
                            color="transparent"
                            outlined
                            tile
                        >
                            <PlanCard title="Golden" price="2500€" :diskInformation="$t('PLAN.disk4')" :priceReduction="$t('PLAN.EARLY')" :targetTo="$t('PLAN.to10')"/>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card
                            color="transparent"
                            outlined
                            tile
                        >
                            <PlanCard :focus="true" figure="diamond" :diskInformation="$t('PLAN.disk10')" title="Diamond" price="3500€" :priceReduction="$t('PLAN.EARLY')" :targetTo="$t('PLAN.to50')"/>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card
                            color="transparent"
                            outlined
                            tile
                        >
                            <PlanCard title="Tailored" figure="hexagon" :price="$t('PLAN.tailored_price')" :targetTo="$t('PLAN.tailored')"/>
                        </v-card>
                    </v-col>  
                </v-row> 
            </v-card>
        </v-container>
    </v-container>
</template>
<script>
    import PlanCard from '@/components/ui/PlanCard.vue'

    export default{
        name: 'SubscriptionPlan',
        components: {
            PlanCard
        },
        data(){
            return{
                timeSwitcher: false
            }
        }
    }
</script>
<style>
.v-input--selection-controls__input .v-input--switch__track{
    background-color: #3D4A6D !important;
}


@media (min-width: 850px) {
    .gradient-plan{
        clip-path: polygon(0 0, 100% 0, 100% 0%, 0% 100%);

    }
}

body .linear-background{
        clip-path: polygon(0 0, 100% 0, 100% 97%, 0% 100%);

    }

@media (min-width: 850px) {
    body .linear-background{
        clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);

    }
}
</style>