import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

const theme1_light = {
    primary: '#0B0E17',
    secondary: '#3D4A6D',
    warning: '#E7C02C',
    accent: '#9DA6BA',
    background_gray: '#DFDFDD'
}


export default new Vuetify({
  options: {
    customProperties: true
  },
    lang: {
      locales: { pt },
      current: 'pt',
    },
    theme: {
      themes: {
        light: theme1_light,
      },
    },
});
