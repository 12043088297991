<template>
    <v-container fluid class="align-start background_gray">
        
        <!-- DESKTOP -->
        <div class="d-none d-lg-block">
            <v-row align="start">
                <v-col cols="12">
                    <v-card class="elevation-0" color="transparent">
                        <v-card-title class="text-h2">
                            <v-spacer></v-spacer>
                            <div>
                                <v-img lazy-src="@/assets/icons/icons/hat.png" src="@/assets/icons/icons/hat.png" contain max-width="80px"></v-img>
                            </div>
                            {{ $t('MOBILE_PRESENTATION.title') }}
                        </v-card-title>
                    </v-card>
                </v-col>
            </v-row>
            <v-container fluid>
                <v-row no-gutters justify="center" align="center">
                    <v-col cols="6" class="d-flex justify-center">
                        <v-img 
                            src="@/assets/imgs/versao mobile 2.png"
                        />   
                    </v-col>
                    <v-col cols="6" style="display: flex; justify-content: center; align-items: center;">
                        
                        <v-btn @click="startVideo" v-show="myVideo.paused" color="transparent elevation-0" fab style="position: absolute; z-index: 9999;">
                            <v-icon size="70" color="primary">mdi-play-circle-outline</v-icon>
                        </v-btn>
                        <video @pause="pauseVideo" @play="startVideo" id="playerMobile" controlsList="nodownload noplaybackrate" 
                            poster="@/assets/covers/video_cover.jpg" 
                            style="width: 100%;" class="rounded-xl">
                            <source :src="video" type="video/mp4" />

                            </video>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <!-- MOBILE-->      
        <div class="d-md-none">
            <v-row align="start">
                <v-col cols="12">
                    <v-card class="elevation-0" color="transparent">
                        <v-card-title class="text-h6">
                            <v-spacer></v-spacer>
                            <div class="mr-8">
                                <v-img src="@/assets/icons/icons/hat.png" contain></v-img>
                            </div>
                            {{ $t('MOBILE_PRESENTATION.title') }}
                        </v-card-title>
                    </v-card>
                </v-col>
            </v-row>  
            <v-row>
                <v-col cols="12">
                    <v-row no-gutters justify="center" align="center">
                        <v-col cols="12" class="d-flex justify-center">
                            <v-container class="d-flex justify-center">
                                <v-img 
                                    src="@/assets/imgs/versao mobile 2.png"
                                    lazy-src="@/assets/imgs/versao mobile 2.png"
                                />   
                            </v-container>
                        </v-col>
                        <v-col cols="12" style="display: flex; justify-content: center; align-items: center;">
                            
                            <v-btn @click="startVideo" v-show="myVideo.paused" color="transparent elevation-0" fab style="position: absolute; z-index: 9999;">
                                <v-icon size="70" color="primary">mdi-play-circle-outline</v-icon>
                            </v-btn>
                            <video @pause="pauseVideo" @play="startVideo" id="player" controlsList="nodownload noplaybackrate" 
                                poster="@/assets/covers/video_cover.jpg" 
                                style="width: 100%;" class="rounded-xl">
                                <source :src="video" type="video/mp4" />

                                </video>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>
<script>
    export default{
        name: 'MobilePresent',
        components:{
            
        },
        data(){
            return{
                loading: true,
                video: "video/RL_Video_apresentacao.mp4",
                myVideo:{
                    paused: true
                }
            }
        },
        mounted(){
            this.loading = false
            this.setupVideos()
        },
        methods:{
            startVideo(){
                let video = document.getElementById('player');
                let videoMobile = document.getElementById('playerMobile');
                video.play();
                videoMobile.play();
                this.myVideo.paused = false
            },
            pauseVideo(){
                let video = document.getElementById('player');
                let videoMobile = document.getElementById('playerMobile');
                video.pause();
                videoMobile.pause();
                this.myVideo.paused = true
            },
            setupVideos() {
                let video = document.getElementById('player');
                video.controls = false
                video.addEventListener('mouseover', () => { video.controls = 'controls' })
                video.addEventListener('mouseout', () => { video.controls = false })
            }
        }
    }
</script>