<template>
    <v-theme-provider root>
        <v-app>
            <Menu @change="menuTabChange" @switchLocale="switchLocale" @openMenu="openMenu" ref="menu"/>

            <v-main>
              <v-container fluid class="ma-0 pa-0 fill-height">
              <router-view :menuTab="menuTab" @menu-tab-change="menuTabChange" @saber-mais="knowMore()"></router-view>
              <Cookie :snackbar="showWindowCookie" @cookie-accept="cookieAccept" @cookie-reject="cookieReject" />
              </v-container>
            </v-main>

            <v-scroll-y-reverse-transition>
              <v-container v-if="mobileMenuOpen" class="primary" fluid style="position: fixed; bottom: 10vh; width: 100%; border-top-left-radius: 10%; border-top-right-radius: 10%; z-index: 100;">
                <v-card color="transparent" class="white--text">
                  <v-card-title class="text-uppercase font-weight-bold text-h4">Menu<v-spacer></v-spacer><v-btn @click="mobileMenuOpen = false" dark icon small><v-icon x-large>mdi-close</v-icon></v-btn></v-card-title>
                  <v-divider color="white"></v-divider>
                  <v-card-text class="white--text text-uppercase font-weight-bold text-body-1">
                      <p style="display: flex; justify-content: left;" class="ml-16" @click="menuTabChange('who_we_are'); mobileMenuOpen = false;">
                        <v-img
                            max-width="30px"
                            src="@/assets/icons/icons/png/icons_Prancheta 1-05.png"
                            lazy-src="@/assets/icons/icons/png/icons_Prancheta 1-05.png"
                        />
                        <span class="ml-6">{{$t('MENU.who_we_are')}}</span>
                      </p>
                  </v-card-text>
                  <v-card-text class="white--text text-uppercase font-weight-bold text-body-1">
                      <p style="display: flex; justify-content: left;" class="ml-16" @click="menuTabChange('advantages'); mobileMenuOpen = false;">
                        <v-img
                            max-width="30px"
                            src="@/assets/icons/icons/png/icons_Prancheta 1-38.png"
                            lazy-src="@/assets/icons/icons/png/icons_Prancheta 1-38.png"
                        />
                        <span class="ml-6">{{$t('MENU.advantages')}}</span>
                      </p>
                  </v-card-text>
                  <v-card-text class="white--text text-uppercase font-weight-bold text-body-1">
                      <p style="display: flex; justify-content: left;" class="ml-16" @click="menuTabChange('subscriptions'); mobileMenuOpen = false;">
                        <v-img
                            max-width="30px"
                            src="@/assets/icons/icons/png/icons_Prancheta 1-143.png"
                            lazy-src="@/assets/icons/icons/png/icons_Prancheta 1-143.png"
                        />
                        <span class="ml-6 mt-1">{{$t('MENU.subscription')}}</span>
                      </p>
                  </v-card-text>
                  <v-card-text class="white--text text-uppercase font-weight-bold text-body-1">
                      <p style="display: flex; justify-content: left;" class="ml-16" @click="registerContactEvent(); menuTabChange('contacts'); mobileMenuOpen = false;">
                        <v-img
                            max-width="30px"
                            src="@/assets/icons/icons/png/icons_Prancheta 1-228.png"
                            lazy-src="@/assets/icons/icons/png/icons_Prancheta 1-228.png"
                        />
                        <span class="ml-6">{{$t('MENU.contact')}}</span>
                      </p>
                  </v-card-text>
                  <v-card-text class="white--text text-uppercase font-weight-bold text-body-1">
                      <p style="display: flex; justify-content: left;" class="ml-16" @click="$router.push('/blog'); mobileMenuOpen = false;">
                        <v-img
                            max-width="30px"
                            src="@/assets/icons/icons/png/icons_Prancheta 1-198.png"
                            lazy-src="@/assets/icons/icons/png/icons_Prancheta 1-198.png"
                        />
                        <span class="ml-6">{{$t('MENU.who_we_are')}}</span>
                      </p>
                  </v-card-text>
                  <v-card-text class="white--text text-uppercase font-weight-bold text-body-1">
                      <p style="display: flex; justify-content: left;" class="ml-16" @click="openReportlake">
                        <v-img
                            max-width="30px"
                            src="@/assets/icons/icons/png/icons_Prancheta 1-32.png"
                            lazy-src="@/assets/icons/icons/png/icons_Prancheta 1-32.png"
                        />
                        <span class="ml-6">Login</span>
                      </p>
                  </v-card-text>
                  
                </v-card>
              </v-container>
            </v-scroll-y-reverse-transition>
            

            <v-footer 
              padless
              fluid
              class="d-none d-lg-block flex ma-0 pa-0"
              color="secondary"
              fill-height>
              <Footer />
            </v-footer>

            <v-footer 
              padless
              fluid
              fixed
              style="z-index: 101;"
              class="d-md-none"
              color="secondary"
              fill-height>
              
              <Footer />
            </v-footer>

        </v-app>
        
    </v-theme-provider>
</template>
<script>
  import Footer from "@/views/Footer.vue"
  import Menu from "@/components/header/Menu.vue"
  import Cookie from "@/components/cookies/Cookie.vue"

  export default {
    components:{
      Menu,
      Footer,
      Cookie,
    },
    watch:{
      menuTab:{
        immediate: true,
        handler(val){
          if(val == null)
            return
          if(val == undefined)
            return

          if(val == '')
            return

          if(val == 'who_we_are')
            return

          if(val == 'contacts'){
            this.menuTab = val
            this.$router.push('/' + this.$i18n.locale + '/contacts')
            return
          }

          if(val == 'blog'){
            this.menuTab = val
            this.$router.push('/' + this.$i18n.locale + '/blog')
            return
          }

          if(val != 'contacts'){
            this.menuTab = val
            if(this.$route.name != undefined)
              this.$router.push('/' + this.$i18n.locale + '/#' + val)
          }
        }
      },
      $route:{
        immediate: true,
        handler(val){
          if(val == null){
            document.title = 'Software de Gestão de Obra - Report Lake'
            return
          }

          if(val.name == 'Trial'){
            document.title = 'Trial do Reportlake - Software de Gestão de Obra'
            return
          }

          document.title = 'Software de Gestão de Obra - Report Lake'
        }
      }
    },
    data(){
      return{
        mobileMenuOpen: false,
        menuTab: '',
        showCookieBar: false
      }
    },    
    computed:{      
      showWindowCookie(){
        return this.showCookieBar
      },
    },
    mounted(){   
      let cookie = this.$cookies.get('reportlake_website_cookie');
      //this.$cookies.set('reportlake_website_cookie', JSON.stringify(cookie), '1s');
      if(cookie == null)
        this.showCookieBar = true

      if(this.$route.params.lang != null)
        this.$i18n.locale = this.$route.params.lang

    },
    methods:{      
      openReportlake(){
          window.open('https://login.reportlake.com/', '_blank')
      },
      cookieReject(){
        this.$cookies.set('reportlake_website_cookie', null, '1s');
        this.showCookieBar = false
      },
      cookieAccept(ob){
        this.$cookies.set('reportlake_website_cookie', JSON.stringify(ob), '7d');
        this.showCookieBar = false
      },  
      switchLocale(l){
          this.$i18n.locale = l

          this.$route.params.lang = this.$i18n.locale
          this.$router.push({name: this.$router.currentRoute.name, params: this.$route.params})
      },
      knowMore(){
        this.$refs.menu.contactTab()
      },  
      registerContactEvent(){
        let cookie = this.$cookies.get('reportlake_website_cookie');

        if(cookie == undefined)
            return
        if(cookie == null)
            return
        if(cookie.statistics == false)
            return

        this.$gtag.event('contact_tag', {
            'event_category': 'Contactos',
            'event_label': 'Contactos',
            'value': 'contact_tag'
        })
      },
      openMenu(){
        this.mobileMenuOpen = !this.mobileMenuOpen
      },
      menuTabChange(v){
        this.menuTab = v
      },
      getVersion(){
        return process.env.VUE_APP_SECRET_VERSION
      },
      goTo(place){
        if(place != 'contacts' && place != 'blog'){
          this.$router.push('/' + place)
          return
        }
        this.$router.push(place)
      }
    },
  }
</script>