<template>
    <v-app-bar
        app
        color="primary"
        dark
    >
        <div class="d-none d-lg-block">
            <div class="d-flex align-center">
                <v-img
                    class="shrink mr-2"
                    contain
                    src="@/assets/icons/fav/favicons-28.png"
                    transition="scale-transition"
                    width="40"
                    @click="$router.push('/')"
                    style="cursor: pointer;"
                />
            </div>
        </div>

        <!-- DESKTOP-->
        <v-spacer class="d-none d-lg-block"></v-spacer>
        <v-tabs right dark class="d-none d-lg-block" v-if="!terms">
            <v-tab @click="menuTab = 'who_we_are'">{{ $t('MENU.who_we_are') }}</v-tab>
            <v-tab @click="menuTab = 'advantages'">{{ $t('MENU.advantages') }}</v-tab>
            <v-tab @click="menuTab = 'subscriptions'">{{ $t('MENU.subscription') }}</v-tab>
            <v-tab id="contact" @click="registerContactEvent(); menuTab = 'contacts'">{{ $t('MENU.contact') }}</v-tab>
            <v-tab @click="menuTab = 'blog'">{{ $t('MENU.blog') }}</v-tab>
            <v-spacer v-if="$route.name == '1'"></v-spacer>             
        </v-tabs>
        <div class="mr-2 ml-4 d-none d-lg-block" style="justify-content: center; align-items: center; display: flex;">
            <v-btn @click="openReportlake" class="font-weight-light white--text elevation-16" rounded outlined>
                {{$t('MENU.login')}}
            </v-btn>
        </div>
        <div class="d-none d-lg-block">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="black"
                    icon
                    style="float: right !important;"
                    v-bind="attrs"
                    v-on="on"
                >
                    <flag :iso="chooseLanguage()" class="elevation-10"/>
                </v-btn>
            </template>
            <v-list>
                <v-list-item>
                    <v-list-item-title>
                        <v-btn
                            color="black"
                            class="mt-2"
                            icon
                            @click="switchLocale('pt')"
                        >
                            <flag :iso="'pt'" class="elevation-10"/>
                        </v-btn>
                        
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>
                        <v-btn
                            color="black"
                            class="mt-2"
                            icon
                            @click="switchLocale('en')"
                        >
                            <flag :iso="'gb'" class="elevation-10"/>
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>
                        <v-btn
                            color="black"
                            class="mt-2"
                            icon
                            @click="switchLocale('it')" 
                        >
                            <flag :iso="'it'" class="elevation-10"/>
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>        
    </div>   

        <!-- MOBILE-->
        <v-row class="d-md-none">
            <v-toolbar fluid color="transparent">
                <div class="d-flex align-center">
                    <v-img
                        class="shrink mr-2"
                        contain
                        src="@/assets/icons/fav/favicons-28.png"
                        transition="scale-transition"
                        width="40"
                    />
                </div>
                <v-spacer></v-spacer>
                <v-scale-transition>
                    <v-img 
                        style="margin: auto; display: block;"
                        max-width="200px"
                        src="@/assets/icons/title_negativo.png"
                        lazy-src="@/assets/icons/title_negativo.png"
                        v-show="showHeader"
                    />
                </v-scale-transition>
                <v-spacer></v-spacer>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            icon
                        v-bind="attrs"
                        v-on="on"
                        >
                            <flag :iso="chooseLanguage()" class="elevation-10"/>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                <v-btn
                                    color="black"
                                    class="mt-2"
                                    icon
                                    @click="switchLocale('pt')"
                                >
                                    <flag :iso="'pt'" class="elevation-10"/>
                                </v-btn>
                                
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>
                                <v-btn
                                    color="black"
                                    class="mt-2"
                                    icon
                                    @click="switchLocale('en')"
                                >
                                    <flag :iso="'gb'" class="elevation-10"/>
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>
                                <v-btn
                                    color="black"
                                    class="mt-2"
                                    icon
                                    @click="switchLocale('it')" 
                                >
                                    <flag :iso="'it'" class="elevation-10"/>
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <v-btn icon right @click="$emit('openMenu')" class="mr-2" v-if="$route.name != 'Privacy'">
                    <v-icon>mdi-menu</v-icon>
                </v-btn>
                
            </v-toolbar>

        </v-row>

    
    </v-app-bar>
</template>
<style>
.v-btn__content{
    width: fit-content;
    height: fit-content;
}
.fi{
    background-size: cover !important;
    line-height: 1em !important;
}
.fi.fis {
    height: 50% !important;
    width: 50% !important;
}
</style>
<script>
export default{
    name: "MenuDesktop",
    props:{
        terms:{
            typeof:Boolean,
            default: false
        }
    },
    data(){
        return{
            showHeader: false,
            menuTab: 'who_we_are'
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods:{     
        openReportlake(){
            window.open('https://login.reportlake.com/', '_blank')
        },
        switchLocale(l){
            this.$emit('switchLocale', l)
        },
        chooseLanguage(){
            return this.$i18n.locale == 'en' ? 'gb' : this.$i18n.locale
        },
        contactTab(){
            document.getElementById('contact').click();
        },
        registerContactEvent(){
            let cookie = this.$cookies.get('reportlake_website_cookie');

            if(cookie == undefined)
                return
            if(cookie == null)
                return
            if(cookie.statistics == false)
                return

            this.$gtag.event('contact_tag', {
                'event_category': 'Contactos',
                'event_label': 'Contactos',
                'value': 'contact_tag'
            })
        },
        handleScroll(){
            if(window.top.scrollY > 0)
                this.showHeader = true
            else this.showHeader = false
        }
    },
    watch:{
        menuTab:{
            immediate: true,
            handler(val){
                if(val == undefined)
                return

                this.$emit('change', val)
            }
        }
    }
}
</script>