<template>
    <v-container fluid v-if="!loading">  
        <!-- DESKTOP -->   
        <v-container class="d-none d-lg-block">
            <v-row class="px-16">
                <v-col cols="12" class="px-16">
                    <v-card class="elevation-10 px-16">
                        <v-card-title>
                            <v-btn dark rounded color="primary" @click="$router.push('/' + $i18n.locale + '/blog')">
                                <v-icon class="mr-2">mdi-arrow-left</v-icon>{{$t('buttons.go_back')}}
                            </v-btn>
                        </v-card-title>
                        <v-card-title class="text-h2 secondary--text" style="word-break: keep-all;">{{ article.title }}</v-card-title>
                        <v-card-subtitle class="text-h4 accent--text text-justify">{{ article.subtitle }}</v-card-subtitle>
                        <v-card-subtitle class="text-caption mt-n8">                            
                            <v-row class="mt-2 ml-2">
                                <v-col cols="auto" class="ma-0 pa-0">
                                    <v-avatar>
                                        <v-img :src="require('@/json_articles/autores/' + article.autor_photo)" contain></v-img>
                                    </v-avatar>
                                </v-col>
                                <v-col cols="auto" class="ma-0 pa-0">
                                    <div class="ml-2">
                                        <span class="accent--text">({{ article.autor }} - {{ article.data }})</span>
                                        <br/>
                                        <gb-social-button 
                                            v-if="article.autor_id != null"
                                            @click="openSocialNetwork('linkedin', $getSocialNetwork(article.autor_id, 'Linkedin'))" 
                                            network="linkedin" size="mini" 
                                            :reverse="true" theme="light" 
                                            style="border: none;" 
                                            class="gb-social-button__focuser ml-n2">
                                        </gb-social-button>
                                    </div>
                                </v-col>
                            </v-row>                            
                        </v-card-subtitle>
                        <v-card-text class="text-center">
                            <v-img contain max-width="500px" style="margin: auto;" :src="require('@/json_articles/images/' + article.id + '/' + article.foto)"></v-img>
                        </v-card-text>
                        <v-card-text class="text-justify text-body-1" v-html="article.body">
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- MOBILE -->   
        <v-container class="d-md-none">
            <v-row>
                <v-col cols="12">
                    <v-card class="elevation-10">
                        <v-card-title>
                            <v-btn dark rounded color="primary" @click="$router.push('/' + $i18n.locale + '/blog')">
                                <v-icon class="mr-2">mdi-arrow-left</v-icon>{{$t('buttons.go_back')}}
                            </v-btn>
                        </v-card-title>
                        <v-card-title class="text-h4 secondary--text" style="word-break: keep-all;">{{ article.title }}</v-card-title>
                        <v-card-subtitle class="text-h6 accent--text">{{ article.subtitle }}</v-card-subtitle>
                        <v-card-subtitle class="text-caption mt-n8">
                            <v-row class="mt-2 ml-2">
                                <v-col cols="auto" class="ma-0 pa-0">
                                    <v-avatar>
                                        <v-img :src="require('@/json_articles/autores/' + article.autor_photo)" contain></v-img>
                                    </v-avatar>
                                </v-col>
                                <v-col cols="auto" class="ma-0 pa-0">
                                    <div class="ml-2">
                                        <span class="accent--text">({{ article.autor }} - {{ article.data }})</span>
                                        <br/>
                                        <gb-social-button
                                            v-if="article.autor_id != null"
                                            @click="openSocialNetwork('linkedin', $getSocialNetwork(article.autor_id, 'Linkedin'))" 
                                            network="linkedin" size="mini" 
                                            :reverse="true" theme="light" 
                                            style="border: none;" 
                                            class="gb-social-button__focuser ml-n2">
                                        </gb-social-button>
                                    </div>
                                </v-col>
                            </v-row>       
                        </v-card-subtitle>
                        <v-card-text class="text-center">
                            <v-img contain max-width="500px" style="margin: auto;" :src="require('@/json_articles/images/' + article.id + '/' + article.foto)"></v-img>
                        </v-card-text>
                        <v-card-text class="text-justify text-body-1 mb-12" v-html="article.body" >
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>
<style>
.gb-social-button__focuser{
  border: none !important;
}
</style>

<script>
import ARTICLES from '@/json_articles/articles.json'

export default{
    data(){
        return{
            loading: true,
            article: null
        }
    },
    mounted(){
        let id = this.$route.params.id

        ARTICLES.articles.forEach(element => {
            if(element.id == id)
                this.article = element
        });

        this.loading = false
    },
    methods:{
      openSocialNetwork(social, link){
        if(social == 'linkedin')
          window.open(link, '_blank')
      }
    }
}
</script>