<template>
    <v-container fluid>
        <!-- DESKTOP -->
        <div class="d-none d-lg-block">
            <v-row>
                <v-col
                    class="pa-0 ma-0"
                    cols="12"
                    sm="6"
                    md="8"
                    style="height: 100vh !important; overflow:auto;"
                >
                    <BlogArticle 
                        v-for="article in articles" :key="article.id"
                        :ID="article.id"
                        :Title="article.title"
                        :SubTitle="article.subtitle"
                        :TextBody="textArticle(article)"
                        :Author="require('@/json_articles/images/' + article.id + '/' + article.foto)"
                        :AuthorName="article.autor"
                        :DataCreation="article.data"
                        :AuthorPhoto="require('@/json_articles/autores/' + article.autor_photo)"
                        :Linkedin="article.autor_id == null ? null : $getSocialNetwork(article.autor_id, 'Linkedin')"
                    />
                </v-col>
                <v-col
                    style="height: 100vh !important; overflow:auto;"
                    class="pa-0 ma-0 search-background"
                    cols="6"
                    md="4"
                >
                    <v-row class="px-2 py-12">
                        <v-col cols="12">
                            <v-text-field   
                                class="my-input"
                                prepend-inner-icon="mdi-magnify"
                                solo
                                v-model="searchText"
                                @blur="search"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">                        
                            <v-card class="elevation-0" color="transparent">
                                <v-card-title class="text-h4 primary--text">
                                    {{$t('PRESENTATION.recent_articles')}}
                                </v-card-title>
                                <v-card-text>
                                    <RecenteArticle 
                                        v-for="(article,index) in getDataArticles" 
                                        :key="index"
                                        :ID="article.id"
                                        :Title="article.title"
                                        :Author="article.autor"
                                        :AutorPhoto="require('@/json_articles/autores/' + article.autor_photo)"
                                        :DataArticle="article.data"
                                        :Photo="require('@/json_articles/images/' + article.id + '/' + article.foto)"
                                        :Linkedin="article.autor_id == null ? null : $getSocialNetwork(article.autor_id, 'Linkedin')"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <!-- MOBILE -->
        <div class="d-md-none mb-12">
            <v-row>
                <v-col
                    class="pa-0 ma-0"
                    cols="12"
                    style="height: 100vh !important; overflow:auto;"
                >
                    <BlogArticle 
                        v-for="article in articles" :key="article.id"
                        :ID="article.id"
                        :Title="article.title"
                        :SubTitle="article.subtitle"
                        :TextBody="textArticle(article)"
                        :Author="require('@/json_articles/images/' + article.id + '/' + article.foto)"
                        :AuthorName="article.autor"
                        :DataCreation="article.data"
                        :AuthorPhoto="require('@/json_articles/autores/' + article.autor_photo)"
                        :Linkedin="article.autor_id == null ? null : $getSocialNetwork(article.autor_id, 'Linkedin')"
                    />
                </v-col>                
            </v-row>
        </div>
    </v-container>
</template>


<style>
.my-input.v-input .v-input__slot {
  border-radius: 100px;
}
.search-background{
    background-color: #9DA6BA !important;
}
::-webkit-scrollbar {
    display: none;
}
</style>
<script>
import BlogArticle from '@/components/Blog/BlogArticle.vue'
import RecenteArticle from '@/components/Blog/RecenteArticle.vue'
import ARTICLES from '@/json_articles/articles.json'

export default{
    components:{
        BlogArticle,
        RecenteArticle
    },
    mounted(){
        this.articles = ARTICLES.articles
        this.search()
    },
    data(){
        return{
            searchText: '',
            dataArticles: [],
            articles: []
        }
    },    
    methods:{
        textArticle(t){
            if(t.body.length > 150)
                return t.body.substring(0, 300) + '...'

            return t.body
        },  
        search(){
            let dataArray = []
            this.articles.forEach(element => {
                if(element.title.includes(this.searchText))
                    dataArray.push(element)
            });
            this.dataArticles = dataArray
        }
    },
    computed:{
        getDataArticles(){
            return this.dataArticles
        }
    },
}
</script>