<template>
    <div>
            <v-img
                :src="getPath"
                width="100px"
                
            />
            <p class="white--text">TEXTO A POR</p>
            </div>
</template>
<script>
    export default{
        name:'MyIcon',
        props:{
            iconProp: String
        },
        data(){
            return{
                icons:[
                    {
                        name: 'hat',
                        path: require('@/assets/icons/icons/white.png')
                    }
                ]
            }
        },
        computed:{
            getPath(){
                let p = ''
                this.icons.forEach(element => {
                    if(element.name == this.iconProp)
                        p = element.path
                });
                return p
            }
        }
    }
</script>