<template>
    <v-container fluid>
        <div class="d-none d-lg-block ma-16 pa-16" v-html="$t('termos')">

            

        </div>
        <div class="d-lg-none" v-html="$t('termos')"></div>
    </v-container>
</template>
<script>
export default{
    name: "Terms",
    mounted(){
        this.$i18n.locale = this.$route.params.lang
    }
}
</script>