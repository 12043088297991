import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../Layout.vue'
import LayoutExternal from '../LayoutExternal.vue'
import HomeView from '../views/HomeView.vue'
import Contacts from '../views/Contacts.vue'
import Blog from '../views/Blog.vue'
import Article from '../components/Blog/Article.vue'
import SuccessTrial from '../views/SuccessTrial.vue'
import Success from '../views/Success.vue'
import CalendlyView from '../views/Calendly/CalendlyView.vue'
import Privacy from '../views/Privacy.vue'
import Terms from '../views/Terms.vue'
import Trial from '@/views/Trial.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:lang/trial',
    name: "Trial_page",
    component: Layout,
    children:[
      {
      path: '/:lang/trial',
      component: Trial
      }
    ]
  },
  {
    path: '/',
    redirect: '/pt'
  },
  {
    path: '/:lang/terms-and-conditions',
    name: 'home',
    component: LayoutExternal,
    children: [
      {
        path: '/:lang/terms-and-conditions',
        name: "Terms",
        component: Terms
      },
    ]
  },  
  {
    path: '/:lang',
    name: 'home',
    component: Layout,
    children: [
      {
        path: '/:lang',
        component: HomeView
      },  
      {
        path: '/:lang/contacts',
        component: Contacts,
        name: 'contacts'
      },
      {
        path: '/contacts/evento_1',
        component: Contacts,
        name: 'contacts-qr'
      },
      {
        path: '/:lang/blog',
        component: Blog,
        name: 'blog',
      },
      {
        path: '/:lang/article/:id',
        component: Article,
        name: 'article',
      },
      {
        path: '/:lang/contacts/success',
        component: Success,
        name: 'success'
      },
      {
        path: '/:lang/success/trial',
        component: SuccessTrial,
        name: 'successTrial'
      },
      {
        path: '/:lang/contacts/evento_1/success',
        component: Success,
        name: 'success-qr'
      },
      {
        path: '/:lang/sucess_ads',
        component: Success,
      },
      {
        path: '/article/:id',
        redirect: '/pt/article/:id'
      },
      {
        path: '/:lang/privacy',
        name: "Privacy",
        component: Privacy
      },
      {
        path: '/:lang/trial',
        name: "Trial",
        component: Trial
      }
    ],
  },
  {
    path: '/schedule-call',
    name: 'calendly',
    component: CalendlyView,
    children : []
  },
  {
    path: '/:language/schedule-call',
    name: 'calendly',
    component: CalendlyView,
    children : []
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
