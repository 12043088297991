<template>
    <v-container fluid>
        <v-row class="justify-center">
                <v-col cols="12">
                    <v-card
                        style="margin: auto; display: block;"
                        class="plan-card"
                        :color="focus ? '#29334B' : '#9DA6BA'" 
                        :elevation="focus ? '16':'0'"
                    >
                    
                        <div class="d-flex justify-center text-caption white--text" style="position: absolute; left:0; right:0;">
                            <v-chip
                                v-show="focus"
                                color="warning"
                                label
                                text-color="white"
                                small
                                >
                                Mais popular
                                <v-icon right>
                                    mdi-star
                                </v-icon>
                            </v-chip>
                        </div>
                        <v-card-title class="d-none d-md-block justify-center text-center font-weight-bold text-h3 white--text">
                            {{ title }}
                        </v-card-title>
                        <v-card-title class="d-md-none justify-center text-h5 text-center font-weight-bold white--text">
                            {{ title }}
                        </v-card-title>
                        <v-card-text class="d-flex justify-center mt-4">
                                <div class="circle" v-if="figure == 'circle'">{{ price }}</div>
                                <div class="diamond mt-6" v-if="figure == 'diamond'"><span><p>{{ price }}</p></span></div>
                                <!-- DESKTOP -->
                                <div v-if="figure=='hexagon'" class="d-none d-lg-block hexagon hexagon1 mt-4"><div class="hexagon-in1"><div class="hexagon-in2"><span style="font-size:1vw;" class="font-weight-bold">{{ price }}</span></div></div></div>
                                <!-- MOBILE -->
                                <div v-if="figure=='hexagon'" class="d-md-none hexagon hexagon1"><div class="hexagon-in1"><div class="hexagon-in2"><span style="font-size:2.2vw;" class="font-weight-bold">{{ price }}</span></div></div></div>
                        </v-card-text>
                        <v-card-text class="d-none d-lg-block d-flex justify-center mt-n8">                            
                            <!-- DESKTOP -->
                            <div :style="figure == 'diamond' ? 'color: #9DA6BA;':'color: #29334B;'" :class="figure == 'diamond' ? 'text-center d-none d-lg-block mt-6 text-h4' : 'text-center d-none d-lg-block text-h4'">{{ priceReduction }}</div>
                            <!-- MOBILE -->
                            <div :class="figure == 'diamond' ? 'text-center d-md-none mt-4 mb-n6 text-body-1' : 'text-center d-md-none text-body-1 error--text'">{{ priceReduction }}</div>
                        </v-card-text>
                        <!-- DESKTOP -->
                        <v-card-actions class="d-none d-lg-block justify-center mt-auto">
                            <div :class="focus ? 'text-center mt-8 white--text text-h6 font-weight-bold' : 'text-center mt-8 primary--text text-h6 font-weight-bold'" style="position: absolute; bottom: 0; left: 0; right: 0;">
                            <v-row class="text-center">
                                <v-col cols="12">
                                    <span class="text-h6"><small>{{ targetTo }}</small></span>
                                </v-col>
                                <v-col cols="12" class="mt-n8">
                                    <span class="text-body-1"><small>{{diskInformation}}</small></span>
                                </v-col>
                            </v-row>
                        </div>
                        </v-card-actions>
                        <!-- MOBILE -->
                        <v-card-actions class="d-md-none justify-center mt-auto white--text">
                            <v-row class="text-center">
                                <v-col cols="12">
                                    <span class="text-body-1"><small>{{ targetTo }}</small></span>
                                </v-col>
                                <v-col cols="12" class="mt-n8">
                                    <span class="text-caption"><small>{{diskInformation}}</small></span>
                                </v-col>
                            </v-row>                            
                        </v-card-actions>                        
                    </v-card>
                </v-col>
                <v-col cols="12" class="mt-4">
                    <div class="text-center">
                        <v-btn bottom color="warning" @click="knowMore">{{ $t('PLAN.select') }}</v-btn>
                    </div>
                </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default{
        name: 'PlanCard',
        props:{
            title: String,
            price: String,
            targetTo: String,
            diskInformation: String,
            priceReduction:{
                typeOf: String,
                default: ''
            },
            focus:{
                typeOf: Boolean,
                default: false
            },
            figure:{
                typeOf: String,
                default: 'circle'
            }
        },
        methods:{
            knowMore(){     
                let cookie = this.$cookies.get('reportlake_website_cookie');

                if(cookie == undefined)
                    return
                if(cookie == null)
                    return
                if(cookie.statistics == false)
                    return

                this.$gtag.event('tag_more', {
                'event_category': 'SaberMais',
                'event_label': 'SaberMais',
                'value': 'tag_more'
                })
                //this.$router.push('/' + this.$i18n.locale + '/contacts')
                this.$router.push('/' + this.$i18n.locale + '/trial')
            }
        }
    }
</script>
<style>
.plan-card{
    width: 150px;
    height: 280px;
    border-radius: 10% !important;
}
.circle {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  font-size: 25px;
  color: #fff;
  text-align: center;
  background: #111B2D
}

.diamond {
    width: 100px;
    height: 100px;
    line-height: 100px;
    color: #fff;;
    text-align: center;
    background: #111B2D;
    rotate: 45deg;
    border-radius: 10% !important;
}

.diamond p{
    rotate: -45deg;
    font-size: 25px;
}

.hexagon
{   overflow: hidden;
    visibility: hidden;
    color: white;
    line-height: 90px;
    text-align: center;
    font-size: 25px;
    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    -o-transform: rotate(120deg);
    transform: rotate(120deg);
}

.hexagon-in1
{   overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}

.hexagon-in2
{   width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: #111B2D;
    visibility: visible;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}

.hexagon1
{   width: 200px;
    height: 100px;
}

.hexagon2
{   width: 100px;
    height: 200px;
    margin: -40px 0 0 10px;
}



@media (min-width: 850px) {
    .plan-card{
        width:  250px;
        height: 370px;
        border-radius: 10% !important;
    }
    .circle {
        width: 9vw;
        height: 9vw;
        line-height: 9vw;
        border-radius: 50%;
        font-weight: bold;
        color: #fff;
        text-align: center;
        background: #111B2D
    }
    .diamond {
        width: 6vw;
        height: 6vw;
        line-height: 6vw;
        font-weight: bold;
        color: #fff;;
        text-align: center;
        background: #111B2D;
        rotate: 45deg;
    }

    .diamond p{
        rotate: -45deg;
    }

    .hexagon
    {   overflow: hidden;
        visibility: hidden;
        color: white;
        text-align: center;
        line-height: 110px;
        font-size: 40px;
        -webkit-transform: rotate(120deg);
        -moz-transform: rotate(120deg);
        -o-transform: rotate(120deg);
        transform: rotate(120deg);
    }

    .hexagon-in1
    {   overflow: hidden;
        width: 100%;
        height: 100%;
        -webkit-transform: rotate(-60deg);
        -moz-transform: rotate(-60deg);
        -o-transform: rotate(-60deg);
        transform: rotate(-60deg);
    }

    .hexagon-in2
    {   width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50%;
        background-color: #111B2D;
        visibility: visible;
        -webkit-transform: rotate(-60deg);
        -moz-transform: rotate(-60deg);
        -o-transform: rotate(-60deg);
        transform: rotate(-60deg);
    }

    .hexagon1
    {   
        width: 325px;
        height: 125px;
    }

    .hexagon2
    {   
        width: 125px;
        height: 325px;
        margin: -80px 0 0 20px;
    }
}

</style>