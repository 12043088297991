<template>
  <div style="width: 100%;" class="ma-0 pa-0">
    <!-- DESKTOP-->
    <v-card
      class="d-none d-lg-block flex ma-0 pa-0"
      color="primary"
      dark
    >
      <v-divider></v-divider>

      <v-card-text class="py-4 text-center white--text text-subtitle-1">
      <span style="cursor: pointer; position: absolute; left: 0;" class="ml-2" dark text @click="openPrivacy">{{$t('privacy_label')}}</span>
        {{ new Date().getFullYear() }} — <strong>{{ $t('developed_by') }} <a href="https://www.dreamdealtech.pt/" class="white--text">Dreamdeal Technologies</a></strong>  
        
        <div style="position: absolute; right: 0; top: 0;" class="d-none d-lg-block mt-4">
          <span class="mr-2" style="cursor: pointer;" @click="openStatus">Reportlake Status</span>
          <span class="mr-2">|</span>
          <span dark text @click="openTerms" class="mr-4" style="cursor: pointer;">{{$t('termos_condicoes')}}</span>
          <v-icon @click="goTo({url: 'https://www.linkedin.com/showcase/report-lake'})" class="mr-2">mdi-linkedin</v-icon>
          <v-icon @click="goTo({url: 'https://www.facebook.com/dreamdealtech'})" class="mr-2">mdi-facebook</v-icon>
        </div>
        

        <div class="d-md-none mt-4">
          <v-icon @click="goTo({url: 'https://www.linkedin.com/showcase/report-lake'})" class="mr-2">mdi-linkedin</v-icon>
          <v-icon @click="goTo({url: 'https://www.facebook.com/dreamdealtech'})" class="mr-2">mdi-facebook</v-icon>
        </div>
          
          
      </v-card-text>
    </v-card>

    <!-- MOBILE -->
    <v-card class="d-md-none"
      color="primary"
      fixed
      dark
    >
      <v-card-text class="py-1 text-center white--text text-caption">
        <v-row>  
          <v-col cols="12" align="center">
            {{ new Date().getFullYear() }} — <strong>{{ $t('developed_by') }} <a href="https://www.dreamdealtech.pt/" class="white--text">Dreamdeal Technologies</a></strong>
          </v-col>
        </v-row>
          <div class="d-md-none">
            <v-row fluid>
              <v-col cols="12">
                <span dark text @click="openTerms" class="mr-2" style="cursor: pointer;">{{$t('termos_condicoes')}}</span>
                <span class="mr-2">|</span>
                <span x-small dark text @click="openPrivacy" style="cursor: pointer;" class="mr-2">{{$t('privacy_label')}}</span>   
                <span class="mr-2">|</span>
                <span class="mr-2" style="cursor: pointer;" @click="openStatus">Status</span>
              </v-col>
              <v-col class="mt-n4" cols="12" align="center">
                <v-icon @click="goTo({url: 'https://www.linkedin.com/showcase/report-lake'})" class="mr-2">mdi-linkedin</v-icon>
                <v-icon @click="goTo({url: 'https://www.facebook.com/dreamdealtech'})" class="mr-2">mdi-facebook</v-icon>
              </v-col>
            </v-row>
           
                
   
                
            
              
          </div>          
      </v-card-text>
    </v-card>
  </div>
</template>
  <script>
  export default {
    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      social_networks:[
        {
            icon: 'mdi-linkedin',
            url:  'https://www.linkedin.com/showcase/report-lake'
        },
        {
            icon: 'mdi-facebook',
            url:  'https://www.facebook.com/dreamdealtech'
        }
      ]
    }),
    methods:{
      openStatus(){
        window.open('https://reportlake.statuspage.io/')
      },
      goTo(social){
          window.open(social.url)
      },
      openPrivacy(){
        window.open('/' + this.$i18n.locale + '/privacy', '_blank')
      },
      openTerms(){
        window.open('/' + this.$i18n.locale + '/terms-and-conditions', '_blank')
      }
    }
  }
</script>