<template>
    <div class="background_gray" style="width: 100%;">
        <v-container fluid  class="secondary">
            
            <!-- DESKTOP -->
            <div class="d-none d-lg-block">
                <v-row >
                    <v-col cols="12" md="6">
                        <v-col cols="12">
                            <v-card class="elevation-0" color="transparent" dark>
                                <v-card-title class="text-h5 font-weight-black">{{ $t('HOME.title') }}</v-card-title>
                                <v-card-text class="font-weight-light text-h6 mb-16" style="white-space: pre-line;">
                                    <span class="text-justify" >{{ $t('HOME.description') }}</span>
                                </v-card-text>
                                <v-card-actions class="mt-n16">
                                    <v-btn color="warning" dark @click="$emit('start_free')">
                                        {{ $t('HOME.btn_gratis') }}
                                    </v-btn>
                                    <v-btn color="warning" dark outlined @click="knowMore()">
                                        <template v-slot:default>
                                            <div style="color: white !important;">{{ $t('HOME.btn_saber_mais') }}</div>
                                        </template>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="8" lg="8" xl="8" class="mt-6">
                            <v-card
                                class="elevation-0" outlined tile color="transparent"
                            >
                                <v-card-text>
                                    <v-carousel :show-arrows="false" :cycle="true" hide-delimiter-background>
                                        <v-carousel-item
                                            v-for="(item,i) in items"
                                            :key="i"
                                        >
                                            <v-row
                                                class="fill-height"
                                                align="center"
                                                justify="center"
                                            >
                                                <div>
                                                    <v-img :src="item.src" contain></v-img>
                                                </div>
                                            </v-row>
                                        </v-carousel-item>
                                    </v-carousel>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card
                                class="elevation-0" outlined tile color="transparent"
                            >
                                <v-card-text class="text-h5 font-weight-bold accent--text">
                                    {{ $t('HOME.designed_for') }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-img 
                            src="@/assets/imgs/computer_edited.jpg"
                            lazy-src="@/assets/imgs/computer_edited.jpg"
                            contain
                        />
                    
                    </v-col>
                </v-row>
            </div>
            <!-- MOBILE -->
            <v-row class="secondary d-md-none">
                <v-col cols="12" md="6">
                    <v-col cols="12">
                        <v-card class="elevation-0" color="transparent" dark>
                            <v-card-title class="text-h6 font-weight-bold text-center">{{ $t('HOME.title') }}</v-card-title>                        
                            <v-col cols="12">
                                <div style="width: 100%;">
                                    <v-img src="@/assets/imgs/computer.png"></v-img>
                                </div>
                            </v-col>
                            <v-card-text class="font-weight-light text-h6 mb-16 text-center" style="white-space: pre-line;">
                                {{ $t('HOME.description') }}
                            </v-card-text>
                            <v-card-actions class="d-flex justify-center">
                                <v-btn color="warning" dark @click="$emit('start_free')">
                                    {{ $t('HOME.btn_gratis') }}
                                </v-btn>
                                <v-btn color="warning" dark outlined @click="$router.push('/contacts')">
                                    <template v-slot:default>
                                        <div style="color: white !important;">{{ $t('HOME.btn_saber_mais') }}</div>
                                    </template>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card
                            class="elevation-0" outlined tile color="transparent"
                        >
                            <v-card-text class="d-none d-sm-flex">
                                <v-carousel :show-arrows="false">
                                    <v-carousel-item
                                        v-for="(item,i) in items"
                                        :key="i"
                                    >
                                        <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center"
                                        >
                                            <div>
                                                <v-img :src="item.src" contain></v-img>
                                            </div>
                                        </v-row>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-card-text>
                            <v-card-text class="text-body-1 font-weight-bold accent--text">
                                {{ $t('HOME.designed_for') }}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
    export default{
        name: 'HomeScreen',
        props: {
            screenSize:{
                typeOf: Number,
                default: 0
            }
        },
        data(){
            return{
                items: [
                    {
                        src: require('@/assets/icons/examples/balloon.png'),
                    },
                    {
                        src: require('@/assets/icons/examples/balloon2.png'),
                    },
                    {
                        src: require('@/assets/icons/examples/balloon3.png'),
                    },
                    {
                        src: require('@/assets/icons/examples/balloon4.png'),
                    },
                ],
            }
        },
        methods:{
            knowMore(){
                this.$emit('saber-mais')
            }
        }
    }
</script>
<style>

body .linear-background{
        clip-path: polygon(0 0, 100% 0, 100% 97%, 0% 100%);

    }

@media (min-width: 850px) {
    body .linear-background{
        clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);

    }
}

body #screen-background{
    
    background-image: url("../assets/imgs/computer.png");
    width: 100%;
    
    background-position: center;

    background-size: cover;

    background-repeat: no-repeat;
}
</style>