<template>
  <v-container class="pa-0" fluid fill-height>
    
    <TitleRP ref="who_we_are"/>
    <HomeScreen @more="scrollToContacts" @start_free="$router.push('/' + $i18n.locale + '/trial')" :screenSize="getHeight" @saber-mais="$emit('saber-mais')"/>
    <Advantages @schedule-meeting="scheduleMeeting"/>
    <MobilePresent />
    <ReportLakeDescription />
    <WithReportLake ref="advantages"/>
    <WithoutReportLake />
    <SubscriptionPlan ref="subscriptions"/>
    <FAQS />
    <PreFooter ref="contacts" />

    <Transition name="bounce">
    <v-btn
        v-if="scheduleBtn"
        rounded
        dark
        fixed
        bottom
        right
        color="warning"
        @click="$router.push('/' + $i18n.locale + '/trial')"
        class="d-none d-lg-block font-weight-bold primary--text elevation-10"
        style="z-index: 99"
      >
      <div class="d-none d-lg-block">        
        {{$t('HOME.btn_gratis')}}!
      </div>
      <div class="d-md-none">
        <v-row>
          <v-col cols="12" class="ma-0 pa-0">
            
          </v-col>
          <v-col cols="12" class="ma-0 pa-0">
            <small>{{$t('HOME.btn_gratis')}}</small>
          </v-col>
        </v-row>
      </div>
    </v-btn>  
  </Transition>    

  </v-container>
</template>

<script>
  import TitleRP from '@/components/Title.vue'
  import HomeScreen from '@/components/HomeScreen.vue'
  import Advantages from '@/components/Advantages.vue'
  import MobilePresent from '@/components/MobilePresent.vue'
  import ReportLakeDescription from '@/components/ReportLakeDescription.vue'
  import WithReportLake from '@/components/WithReportLake.vue'
  import WithoutReportLake from '@/components/WithoutReportLake.vue'
  import SubscriptionPlan from '@/components/SubscriptionPlan.vue'
  import PreFooter from '@/components/PreFooter.vue'
  import FAQS from '@/components/FAQS.vue'


  export default {
    name: 'Home',
    components:{
      TitleRP,
      HomeScreen,
      Advantages,
      MobilePresent,
      ReportLakeDescription,
      WithReportLake,
      WithoutReportLake,
      SubscriptionPlan,
      PreFooter,
      FAQS
    },
    props:{
      menuTab: String
    },
    data(){
      return{
        window: {
            width: 0,
            height: 0
        },
        scheduleBtn: true
      }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('scroll', this.handleScroll);
        this.handleResize();
    },
    unmounted () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted(){
      if(this.$route.hash != undefined){
        if(this.$route.hash == ''){
          window.scrollTo(0, 0)
          return
        }
        this.$emit('menu-tab-change', this.$route.hash.split('#')[1])
        this.scrollTo(this.$route.hash.split('#')[1])
      }
    },
    watch:{
      menuTab: function(v){
        this.scrollTo(v)
      }
    },
    methods:{ 
      handleScroll(){          
          var limit = document.body.offsetHeight - window.innerHeight
          
          if((window.scrollY + 10) >= limit)
            this.scheduleBtn = false
          else
            this.scheduleBtn = true
      },
      async scrollTo(v){
        if(v == 'advantages')
          this.$refs.advantages.$el.scrollIntoView({ behavior: 'smooth' })
        if(v == 'contacts')
          this.$refs.contacts.$el.scrollIntoView({ behavior: 'smooth' })
        if(v == 'subscriptions')
          this.$refs.subscriptions.$el.scrollIntoView({ behavior: 'smooth' })
        if(v == 'who_we_are')
          this.$refs.who_we_are.$el.scrollIntoView({ behavior: 'smooth' })
      },
      scrollToContacts(){
        this.$refs.contacts.$el.scrollIntoView({ behavior: 'smooth' })
      },
      handleResize() {
          this.window.width = window.innerWidth;
          this.window.height = window.innerHeight;
      },
      scheduleMeeting(){
        let cookie = this.$cookies.get('reportlake_website_cookie');

        if(cookie == undefined)
            return
        if(cookie == null)
            return
        if(cookie.statistics == false)
            return
        
        this.$gtag.event('call_tag', {
          'event_category': 'AgendarChamada',
          'event_label': 'AgendarChamada',
          'value': 'call_tag'
        })
        window.open('/' + this.$i18n.locale + '/schedule-call', '_blank')
      }
    },
    computed:{  
      getHeight(){
          return this.window.height;
      },
    }
  }
</script>
