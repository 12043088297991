<template>
    <div>
        <v-container fluid class="d-none d-lg-block px-16 py-16">     
            <!-- DESKTOP -->   
            <v-container fluid>
            <div class="text-center"> 
                <v-card style="margin: auto;" class="elevation-0 transparent mt-n12">
                    <v-img 
                        style="border-image: linear-gradient(#0000 25%,#29334B 0 75%,#0000 0) fill 0//0 100vw;"
                        :src="Author"
                        contain
                        height="300px" 
                    >
                    </v-img>
                </v-card>      
            </div>   
            </v-container>
            <div class="center-div">
                <v-card class="elevation-0 mt-16">
                    <v-card-title class="text-h4" style="word-break: keep-all;">{{ Title }}</v-card-title>
                    <v-card-subtitle class="text-h6" style="word-break: keep-all;">{{ SubTitle }}</v-card-subtitle>
                    <v-card-subtitle class="text-body-2">({{ DataCreation }})</v-card-subtitle>
                    <v-card-text class="text-justify text-body-1" v-html="TextBody">
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="12" md="6" class="text-left">
                                <v-row class="ml-2">
                                    <v-col cols="auto" class="ma-0 pa-0">
                                        <v-avatar>
                                            <img
                                                :src="AuthorPhoto"
                                                alt="John"
                                            >
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="auto" class="ma-0 pa-0">
                                        <div>
                                            <span class="font-weight-bold ml-2">{{ AuthorName }}</span>
                                            <br/>
                                            <gb-social-button 
                                                v-if="Linkedin!=null"
                                                @click="openSocialNetwork('linkedin', Linkedin)" 
                                                network="linkedin" size="mini" 
                                                :reverse="true" theme="light" 
                                                style="border: none;" 
                                                class="gb-social-button__focuser">
                                            </gb-social-button>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6" class="text-right">
                                <v-btn color="warning" @click="$router.push('/' + $i18n.locale + '/article/' + ID)">
                                    <v-icon class="mr-2">mdi-eye</v-icon>{{$t('buttons.look')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </div>
        </v-container>
            <!-- MOBILE -->
        <v-container fluid class="d-md-none">     
            <v-container class="" fluid>
                <v-card style="margin: auto;" class="elevation-24">
                        <v-img 
                            :src="Author"
                            contain
                        >
                        </v-img>
                    </v-card>      
            </v-container>
            <div class="center-div">
                <v-card class="elevation-0 mt-16">
                    <v-card-title class="text-h6">{{ Title }}</v-card-title>
                    <v-card-subtitle class="text-body-1">{{ SubTitle }}</v-card-subtitle>
                    <v-card-subtitle class="text-caption mt-n8">({{ DataCreation }})</v-card-subtitle>
                    <v-card-text class="text-justify text-body-1" v-html="TextBody">
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="12" md="6" class="text-left">
                                <v-row class="ml-2">
                                    <v-col cols="auto" class="ma-0 pa-0">
                                        <v-avatar>
                                            <img
                                                :src="AuthorPhoto"
                                                alt="John"
                                            >
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="auto" class="ma-0 pa-0">
                                        <div>
                                            <span class="font-weight-bold ml-2">{{ AuthorName }}</span>
                                            <br/>
                                            <gb-social-button 
                                                v-if="Linkedin != null"
                                                @click="openSocialNetwork('linkedin', Linkedin)" 
                                                network="linkedin" size="mini" 
                                                :reverse="true" theme="light" 
                                                style="border: none;" 
                                                class="gb-social-button__focuser">
                                            </gb-social-button>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6" class="text-right">
                                <v-btn color="warning" @click="$router.push('/' + $i18n.locale + '/article/' + ID)">
                                    <v-icon class="mr-2">mdi-eye</v-icon>{{$t('buttons.look')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </div>
        </v-container>
        <v-divider></v-divider>
    </div>
</template>
<script>
export default{
    props:{
        ID: String,
        Title: String,
        SubTitle: String,
        TextBody:String,
        Author:String,
        AuthorName: String,
        DataCreation: String,
        AuthorPhoto: String,
        Linkedin: String
    },
    methods:{
      openSocialNetwork(social, link){
        if(social == 'linkedin')
          window.open(link, '_blank')
      }
    }
}
</script>
<style>
.gb-social-button__focuser{
  border: none !important;
}

.center-div{
        margin: auto; width: 100%;
    }

@media (min-width: 850px) {
    .center-div{
        margin: auto; width: 65%;
    }

    .container {
        width: 100vw; 
        justify-content: center; 
        position: relative;
    }

    .line-divider {
        height: 200px;
        display: flex;
        background-color: #29334B;
        align-items: center;
        position: relative;
    }

    .line {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 25px;
        z-index: -1;
        transform: translateY(-50%);
    }

    .line-divider img {
        height: 300px;
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
    }

    .article-photo{
        display: block; 
        position: fixed; 
    }
}

</style>